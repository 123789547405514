import React from 'react';

import Hero from '../components/Hero';
import { useSiteQuery } from '../hooks';
import { renderPageContent } from '../lib/ContentRenderer';
import ErrorPage from './_error';

import query from './index.graphql';

import styles from './index.module.scss';
import cc from "classcat";

const Index = () => {
  const [{ data, fetching, error }] = useSiteQuery<HomepageQuery>({ query });

  if (error) {
    return <ErrorPage statusCode={500} />;
  }

  if (data && data?.entry && !fetching) {
    const entry = data?.entry;
    const isHomePage = data?.entry?.slug === 'homepage';

    return (
      <div className={cc([styles.index])}>
        {entry.hero?.[0] && !entry.promoBanner?.[0] && <Hero heroArea={entry.hero[0]} isHomePage={isHomePage} />}
        {entry.hero?.[0] && entry.promoBanner?.[0] && <Hero heroArea={entry.hero[0]} promoBanner={entry.promoBanner[0]} isHomePage={isHomePage} />}
        {entry.pageContent?.map((row) => renderPageContent(row!, false, entry))}
      </div>
    );
  }
  return null;
};

export default Index;
